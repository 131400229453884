import React, {useState, useEffect} from 'react';
import {View} from 'react-native';
import _ from 'lodash';
import {Divider} from 'react-native-paper';

import sy from '~/styles';
import {
  Dialog,
  FormNumberInput,
  Text,
  RadioButton,
} from '~/components/controls';
import Format from '~/lib/format';

export const HOUR_TYPES = {
  work: 'work',
  suggested: 'suggested',
  custom: 'custom',
};

const DurationDialog = ({
  priceCheck,
  duration: initial_value,
  work_source,
  work_time,
  sla,
  onChange,
  onDismiss,
}) => {
  const [duration, setDuration] = useState(initial_value);
  const [type, setType] = useState(HOUR_TYPES.custom);

  useEffect(() => {
    if (_.isNumber(work_time) && initial_value === work_time) {
      setType(HOUR_TYPES.work);
    } else if (
      (_.isNumber(priceCheck?.suggested_duration) &&
        initial_value === priceCheck?.suggested_duration) ||
      (_.isNumber(sla?.duration) && initial_value === sla?.duration)
    ) {
      setType(HOUR_TYPES.suggested);
    }
  }, [priceCheck, initial_value, work_time, sla]);

  return (
    <Dialog
      visible={true}
      title="Arbeidstijd"
      onDismiss={() => {
        onChange(initial_value);
        onDismiss();
      }}
      buttons={[
        {
          text: 'Annuleren',
          onPress: () => {
            onChange(initial_value);
            onDismiss();
          },
        },
        {
          text: 'OK',
          onPress: () => {
            onChange(duration, type);
            onDismiss();
          },
        },
      ]}
      options={{noPaddingContent: true}}>
      <View>
        {_.isNumber(work_time) && (
          <>
            <View
              style={[
                sy['flex-row'],
                sy['items-center'],
                sy['gap-4'],
                sy['p-4'],
              ]}>
              <RadioButton
                checked={type === HOUR_TYPES.work}
                onPress={() => {
                  setType(HOUR_TYPES.work);
                  setDuration(work_time);
                }}
              />
              <View style={[sy['flex-1']]}>
                <Text>Fabrikant</Text>
                {work_source !== 'Fabrikant' && (
                  <Text style={[sy.smallRegular, sy['text-lightgray']]}>
                    TecRMI
                  </Text>
                )}
              </View>
              <Text style={[sy.smallRegular, sy['self-start']]}>
                {Format.number(work_time, 2)}&nbsp;u
              </Text>
            </View>
            <Divider style={[sy['bg-gray95'], sy['ml-14']]} />
          </>
        )}
        {_.isNumber(sla?.duration) && (
          <>
            <View
              style={[
                sy['flex-row'],
                sy['items-center'],
                sy['gap-4'],
                sy['p-4'],
              ]}>
              <RadioButton
                checked={type === HOUR_TYPES.suggested}
                onPress={() => {
                  setType(HOUR_TYPES.suggested);
                  setDuration(sla?.duration);
                }}
              />
              <View style={[sy['flex-1']]}>
                <Text>SLA</Text>
                <Text style={[sy.smallRegular, sy['text-lightgray']]}>
                  Bahnkick
                </Text>
              </View>
              <Text style={[sy.smallRegular, sy['self-start']]}>
                {Format.number(sla.duration, 2)}&nbsp;u
              </Text>
            </View>
            <Divider style={[sy['bg-gray95'], sy['ml-14']]} />
          </>
        )}
        {_.isNumber(priceCheck?.suggested_duration) && (
          <>
            <View
              style={[
                sy['flex-row'],
                sy['items-center'],
                sy['gap-4'],
                sy['p-4'],
              ]}>
              <RadioButton
                checked={type === HOUR_TYPES.suggested}
                onPress={() => {
                  setType(HOUR_TYPES.suggested);
                  setDuration(priceCheck?.suggested_duration);
                }}
              />
              <View style={[sy['flex-1']]}>
                <Text>Meest goedgekeurd</Text>
                <Text style={[sy.smallRegular, sy['text-lightgray']]}>
                  Bahnkick
                </Text>
              </View>
              <Text style={[sy.smallRegular, sy['self-start']]}>
                {Format.number(priceCheck?.suggested_duration, 2)}&nbsp;u
              </Text>
            </View>
            <Divider style={[sy['bg-gray95'], sy['ml-14']]} />
          </>
        )}
        <View
          style={[sy['flex-row'], sy['items-center'], sy['gap-4'], sy['p-4']]}>
          <RadioButton
            checked={type === HOUR_TYPES.custom}
            onPress={() => setType(HOUR_TYPES.custom)}
          />
          <FormNumberInput
            style={[sy['flex-1']]}
            label="Aantal uren"
            value={duration}
            digits={2}
            onChangeNumber={setDuration}
            onFocus={() => setType(HOUR_TYPES.custom)}
          />
        </View>
      </View>
    </Dialog>
  );
};

export default DurationDialog;
