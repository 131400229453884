import React, {useEffect, useState, useContext, useRef} from 'react';
import {View, ScrollView, FlatList, TextInput, Animated} from 'react-native';
import {Divider} from 'react-native-paper';
import {useNavigation, useIsFocused} from '@react-navigation/native';
import moment from 'moment';
import _ from 'lodash';

import sy from '~/styles';
import {
  Text,
  ServiceRequestStatusIcon,
  ReplacementTileIcon,
  Pressable,
  FloatingAddButton,
  UserLabel,
} from '~/components/controls';
import {DealerHeader} from '~/components/shared';
import {
  STATUS_DESCRIPTIONS,
  STATUS_ORDER,
  EXPIRED_STATUS,
} from '~/types/statuses';
import {service_request as serviceRequestApi} from '~/api/private';
import UserContext from '~/components/context/UserContext';
import {useDispatch, useSelector} from '~/lib/hooks';
import Format from '~/lib/format';
import {SERVICE_TYPE_CODES} from '~/types/services';
import {setAppIsLoading, setAppDoneLoading} from '~/actions';
import {OVERVIEW_REQUEST_TYPES, IsReplacementVehicle} from '~/types/request';

import ChevronLeftIcon from '~/images/md-icons/chevron_left/materialicons/24px.svg';
import SearchIcon from '~/images/md-icons/search/materialicons/24px.svg';
import CarRepairIcon from '~/images/md-icons/car_repair/materialicons/24px.svg';
import ApprovalIcon from '~/images/md-icons/approval/materialicons/24px.svg';
import TireRepairIcon from '~/images/md-icons/tire_repair/materialicons/24px.svg';
import BuildIcon from '~/images/md-icons/build_repair/materialicons/24px.svg';
import DirectionsCarIcon from '~/images/md-icons/directions_car/materialicons/24px.svg';
import ExpandLessIcon from '~/images/md-icons/expand_less/materialicons/24px.svg';
import ExpandMoreIcon from '~/images/md-icons/expand_more/materialicons/24px.svg';
import WarningAmberIcon from '~/images/md-icons/warning_amber/materialicons/24px.svg';
import WarningIcon from '~/images/md-icons/warning/materialicons/24px.svg';

const DIVIDER_HEIGHT = 1;
const MONTH_HEIGHT = 53;
const REQUEST_HEIGHT = 92;

const StatusHeader = ({status, count, onBack}) => (
  <View style={[sy['flex-row']]}>
    <Pressable style={[sy['p-4']]} onPress={onBack}>
      <ChevronLeftIcon />
    </Pressable>
    <View
      style={[
        sy['flex-1'],
        sy['p-4'],
        sy['items-center'],
        sy['flex-row'],
        sy['justify-center'],
        sy['gap-2'],
      ]}>
      <Text style={[sy.largePlus, {lineHeight: 24}, sy.truncate]}>
        {status === EXPIRED_STATUS.EXPIRED_60_DAYS ? (
          <>Ouder dan 60 dagen</>
        ) : status === EXPIRED_STATUS.EXPIRED_30_DAYS ? (
          <>Ouder dan 30 dagen</>
        ) : (
          STATUS_DESCRIPTIONS[status]
        )}
      </Text>
      <Text style={sy.largePlus}>&middot;</Text>
      <Text
        style={[
          sy.largePlus,
          {
            fontFamily: 'Roboto_500Medium',
          },
        ]}>
        {count}
      </Text>
    </View>
    <View style={[sy['p-4']]}>
      {status === EXPIRED_STATUS.EXPIRED_60_DAYS ? (
        <WarningIcon fill="#972727" />
      ) : status === EXPIRED_STATUS.EXPIRED_30_DAYS ? (
        <WarningAmberIcon fill="#FF6C00" />
      ) : (
        <ServiceRequestStatusIcon status={status} />
      )}
    </View>
  </View>
);

const StatusCard = ({status, count, onPress}) => (
  <>
    <Pressable onPress={onPress} style={sy['bg-white']}>
      <View
        style={[
          sy['p-4'],
          sy['flex-row'],
          sy['justify-between'],
          sy['items-center'],
        ]}>
        <View style={[sy['flex-row'], sy['items-center']]}>
          {status === EXPIRED_STATUS.EXPIRED_60_DAYS ? (
            <>
              <WarningIcon fill="#972727" style={sy['mr-8']} />
              <Text style={sy.mediumBold}>Ouder dan 60 dagen</Text>
            </>
          ) : status === EXPIRED_STATUS.EXPIRED_30_DAYS ? (
            <>
              <WarningAmberIcon fill="#FF6C00" style={sy['mr-8']} />
              <Text style={sy.mediumBold}>Ouder dan 30 dagen</Text>
            </>
          ) : (
            <>
              <ServiceRequestStatusIcon status={status} style={[sy['mr-8']]} />
              <Text style={sy.mediumBold}>{STATUS_DESCRIPTIONS[status]}</Text>
            </>
          )}
        </View>
        <Text style={[sy['text-lightgray'], sy.smallRegular]}>
          {Format.number(count, 0, true)}
        </Text>
      </View>
    </Pressable>
    <Divider style={sy['bg-gray95']} />
  </>
);

const Type = ({type, style}) => {
  if (!type) {
    return null;
  }

  let icon;
  switch (type) {
    case SERVICE_TYPE_CODES.Maintenance:
      icon = <CarRepairIcon fill="#828282" style={style} />;
      break;
    case SERVICE_TYPE_CODES.Inspection:
      icon = <ApprovalIcon fill="#828282" style={style} />;
      break;
    case SERVICE_TYPE_CODES.Tires:
      icon = <TireRepairIcon fill="#828282" style={style} />;
      break;
    default:
      icon = <BuildIcon fill="#828282" style={style} />;
      break;
  }

  return icon;
};

const Day = ({day}) => (
  <>
    <Text style={[sy.xxxLarge, {color: '#666666'}]}>
      {moment(day).format('D')}
    </Text>
    <Text style={[sy.smallRegular, {color: '#666666'}]}>
      {moment(day).format('dd')}
    </Text>
  </>
);

const Request = ({request, index}) => {
  const navigation = useNavigation();
  const {
    id,
    parent_id,
    request_type,
    status,
    license,
    car_name,
    lessor,
    kvk_name,
    datetime,
    replacement_vehicle,
    assigned_user_id,
  } = request;
  const [info, setInfo] = useState(null);
  useEffect(() => {
    const fetch = async () => {
      const {result} = await serviceRequestApi.calendar_info(id);
      setInfo(result);
    };

    fetch();
  }, [id, status]);

  return (
    <>
      <Pressable
        style={[sy['flex-row'], sy['bg-white'], {height: REQUEST_HEIGHT}]}
        onPress={() => {
          if (IsReplacementVehicle(request_type)) {
            navigation.navigate('Replacement', {request_id: parent_id});
          } else {
            navigation.navigate('Request', {request_id: id});
          }
        }}>
        <View style={[sy['p-4'], sy['pr-0'], {width: 56}]}>
          {index === 0 && <Day day={datetime} />}
        </View>
        <View style={[sy['flex-1'], sy['flex-row']]}>
          <View style={[sy['p-4']]}>
            {IsReplacementVehicle(request_type) ? (
              <DirectionsCarIcon fill="#4A4A49" width={24} height={24} />
            ) : (
              <ServiceRequestStatusIcon status={status} />
            )}
          </View>

          <View style={[sy['flex-1'], sy['py-4']]}>
            <Text style={[sy.mediumPlus, sy.truncate]}>
              {Format.license(license)}
            </Text>
            <View style={[sy['flex-row']]}>
              <Text style={[sy.smallRegular, sy.truncate]}>{car_name}</Text>
            </View>
            <View style={[sy['flex-row']]}>
              <Text style={[sy.smallRegular, sy.truncate]}>
                {kvk_name ?? lessor}
              </Text>
            </View>
          </View>

          <View style={[sy['p-4']]}>
            <View style={{flex: 0, minWidth: 'auto', alignItems: 'flex-end'}}>
              <View style={[sy['flex-row'], sy['gap-2']]}>
                {IsReplacementVehicle(request_type) ? (
                  <ServiceRequestStatusIcon
                    status={status}
                    iconProps={{height: 18, width: 18}}
                  />
                ) : (
                  <>
                    {info?.icons.map((icon) => (
                      <Type
                        key={icon}
                        type={icon}
                        style={{height: 18, width: 18}}
                      />
                    ))}
                    {replacement_vehicle && (
                      <ReplacementTileIcon
                        request_id={id}
                        type={replacement_vehicle}
                        fill="#828282"
                        style={{height: 18, width: 18}}
                      />
                    )}
                  </>
                )}
              </View>
            </View>
          </View>
        </View>
        <UserLabel key={id} user_id={assigned_user_id} />
      </Pressable>
      <Divider style={[{backgroundColor: '#DCDCDC'}]} />
    </>
  );
};

const Month = ({date}) => (
  <>
    <View
      style={[
        sy['bg-gray95'],
        sy['p-4'],
        sy['flex-row'],
        sy['justify-between'],
        {height: MONTH_HEIGHT},
      ]}>
      <Text style={sy.mediumBold}>
        {moment(date, 'YYYY-MM').format('MMMM YYYY')}
      </Text>
    </View>
    <Divider style={[{backgroundColor: '#DCDCDC'}]} />
  </>
);

const Grouping = ({header, count, children}) => {
  const [expand, setExpand] = useState(true);

  return (
    <View>
      <Pressable
        style={[
          sy['flex-row'],
          sy['items-center'],
          sy['justify-between'],
          sy['p-4'],
          sy['gap-4'],
          sy['bg-white'],
        ]}
        onPress={() => setExpand(!expand)}>
        <View style={[sy['flex-row'], sy['items-center'], sy['gap-8']]}>
          {expand ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          <Text style={[sy.mediumPlus, sy.truncate]}>{header}</Text>
        </View>
        <Text style={[sy['text-lightgray'], sy.smallRegular]}>
          {Format.number(count, 0, true)}
        </Text>
      </Pressable>
      <Divider style={sy['bg-gray95']} />
      {expand && <>{children}</>}
    </View>
  );
};

const List = ({overview}) => {
  const navigation = useNavigation();

  const request_count = Object.values(
    overview[OVERVIEW_REQUEST_TYPES.REQUEST] ?? {},
  ).reduce((acc, count) => acc + count, 0);

  const replacement_count = Object.values(
    overview[OVERVIEW_REQUEST_TYPES.REPLACEMENT_VEHICLE],
  ).reduce((acc, count) => acc + count, 0);

  return (
    <ScrollView
      style={[sy['flex-1'], sy['bg-gray95']]}
      contentContainerStyle={{marginBottom: 86}}>
      {overview[OVERVIEW_REQUEST_TYPES.EXPIRED]?.[
        EXPIRED_STATUS.EXPIRED_60_DAYS
      ] ? (
        <StatusCard
          status={EXPIRED_STATUS.EXPIRED_60_DAYS}
          count={
            overview[OVERVIEW_REQUEST_TYPES.EXPIRED][
              EXPIRED_STATUS.EXPIRED_60_DAYS
            ]
          }
          onPress={() =>
            navigation.push('Tabs', {
              screen: 'Search',
              params: {
                status: EXPIRED_STATUS.EXPIRED_60_DAYS,
                type: OVERVIEW_REQUEST_TYPES.EXPIRED,
              },
            })
          }
        />
      ) : overview[OVERVIEW_REQUEST_TYPES.EXPIRED]?.[
          EXPIRED_STATUS.EXPIRED_30_DAYS
        ] ? (
        <StatusCard
          status={EXPIRED_STATUS.EXPIRED_30_DAYS}
          count={
            overview[OVERVIEW_REQUEST_TYPES.EXPIRED][
              EXPIRED_STATUS.EXPIRED_30_DAYS
            ]
          }
          onPress={() =>
            navigation.push('Tabs', {
              screen: 'Search',
              params: {
                status: EXPIRED_STATUS.EXPIRED_30_DAYS,
                type: OVERVIEW_REQUEST_TYPES.EXPIRED,
              },
            })
          }
        />
      ) : null}
      <Grouping header="Werkopdracht" count={request_count}>
        {Object.keys(overview[OVERVIEW_REQUEST_TYPES.REQUEST] ?? {})
          .sort((a, b) => STATUS_ORDER.indexOf(a) - STATUS_ORDER.indexOf(b))
          .map((status) => (
            <StatusCard
              key={status}
              status={status}
              count={overview[OVERVIEW_REQUEST_TYPES.REQUEST][status]}
              onPress={() =>
                navigation.push('Tabs', {
                  screen: 'Search',
                  params: {status, type: OVERVIEW_REQUEST_TYPES.REQUEST},
                })
              }
            />
          ))}
      </Grouping>
      <Grouping header="Vervangende auto" count={replacement_count}>
        {Object.keys(overview[OVERVIEW_REQUEST_TYPES.REPLACEMENT_VEHICLE])
          .sort((a, b) => STATUS_ORDER.indexOf(a) - STATUS_ORDER.indexOf(b))
          .map((status) => (
            <StatusCard
              key={status}
              status={status}
              count={
                overview[OVERVIEW_REQUEST_TYPES.REPLACEMENT_VEHICLE][status]
              }
              onPress={() =>
                navigation.push('Tabs', {
                  screen: 'Search',
                  params: {
                    status,
                    type: OVERVIEW_REQUEST_TYPES.REPLACEMENT_VEHICLE,
                  },
                })
              }
            />
          ))}
      </Grouping>
    </ScrollView>
  );
};

const doSearch = _.debounce(
  async ({prefix, selectedType, selectedStatus}, setSelected, dispatch) => {
    try {
      let data;
      if (prefix?.length > 2) {
        ({result: data} = await serviceRequestApi.search({
          prefix,
        }));
      } else if (selectedStatus) {
        ({result: data} = await serviceRequestApi.search({
          selectedType,
          selectedStatus,
        }));
      }

      if (data) {
        setSelected(data);
      } else {
        setSelected(null);
      }
    } catch {}

    dispatch(setAppDoneLoading());
  },
  200,
);

const Search = ({navigation, route}) => {
  const isFocused = useIsFocused();
  const dispatch = useDispatch();

  const selectedType = route.params?.type;
  const selectedStatus = route.params?.status;

  const [overview, setOverview] = useState(null);
  const [selected, setSelected] = useState(null);
  const [prefix, setPrefix] = useState('');

  const {me} = useContext(UserContext);

  const forceFetch = useSelector((state) => state.serviceRequestInsert);
  const forceRefresh = useSelector((state) => state.serviceRequestUpdate);

  const cancelAnim = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    if (prefix.length) {
      Animated.timing(cancelAnim, {
        toValue: 100,
        duration: 250,
        useNativeDriver: true,
      }).start();
    } else {
      Animated.timing(cancelAnim, {
        toValue: 0,
        duration: 0,
        useNativeDriver: true,
      }).start();
    }
  }, [cancelAnim, prefix]);

  useEffect(() => {
    if (!isFocused) {
      return;
    }

    dispatch(setAppIsLoading());

    return () => {
      dispatch(setAppDoneLoading());
    };
  }, [isFocused, dispatch]);

  useEffect(() => {
    if (selectedStatus) {
      return;
    }

    const fetch = async () => {
      const result = await serviceRequestApi.overview();

      dispatch(setAppDoneLoading());

      if (!result) {
        return;
      }

      const {result: overview} = result;
      setOverview(overview);
    };

    if (isFocused) {
      fetch();
    }
  }, [
    isFocused,
    me,
    selectedStatus,
    forceFetch,
    forceRefresh,
    dispatch,
    setOverview,
  ]);

  useEffect(() => {
    if (!isFocused) {
      return;
    }

    if (_.isNil(selectedStatus) || !_.isNil(prefix)) {
      doSearch({prefix, selectedType, selectedStatus}, setSelected, dispatch);
    }
  }, [isFocused, prefix, selectedStatus, selectedType]);

  return (
    <View style={sy.mainView}>
      {selectedStatus ? (
        <StatusHeader
          status={selectedStatus}
          count={selected?.filter((item) => item.type === 'request').length}
          onBack={() => navigation.navigate('Search')}
        />
      ) : (
        <View style={[sy['px-4'], sy['pt-4'], sy['pb-3']]}>
          <DealerHeader
            titleStyle={{textTransform: 'capitalize'}}
            title="Zoeken"
          />

          <View style={[sy['flex-row'], sy['items-center']]}>
            <View
              style={[
                sy['flex-row'],
                sy['flex-1'],
                sy['p-2'],
                sy['mt-2'],
                sy['items-center'],
                {
                  borderRadius: 10,
                  backgroundColor: '#EBEBEB',
                },
              ]}>
              <SearchIcon
                fill={prefix.length ? '#4A4A49' : '#828282'}
                width={18}
                height={18}
              />
              <TextInput
                style={[
                  sy.regular,
                  sy['flex-1'],
                  {
                    color: prefix?.length > 0 ? '#4A4A49' : '#828282',
                    marginLeft: 28,
                  },
                ]}
                placeholder={'Bahnkick'}
                value={prefix}
                onChangeText={setPrefix}
              />
            </View>
            <Animated.View
              style={[
                sy['mt-2'],
                {
                  height: 29,
                  paddingLeft: prefix.length ? 8 : 0,
                  maxWidth: cancelAnim,
                  justifyContent: 'center',
                },
              ]}>
              <Pressable onPress={() => setPrefix('')}>
                <Text
                  style={[
                    sy.regular,
                    {
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                    },
                  ]}>
                  Annuleren
                </Text>
              </Pressable>
            </Animated.View>
          </View>
        </View>
      )}
      <Divider />

      {selected ? (
        <FlatList
          style={[sy['bg-gray95']]}
          data={selected}
          renderItem={({item}) => (
            <>
              {item.type === 'month' && <Month date={item.value} />}
              {item.type === 'request' && <Request {...item.value} />}
            </>
          )}
          initialNumToRender={10}
          getItemLayout={(data, index) => {
            let offset = 0;
            for (let i = 0; i < index; i++) {
              const item = data[i];
              if (item.type === 'month') {
                offset += MONTH_HEIGHT + DIVIDER_HEIGHT;
              } else {
                offset += REQUEST_HEIGHT + DIVIDER_HEIGHT;
              }
            }

            return {
              length:
                data[index].type === 'month'
                  ? MONTH_HEIGHT + DIVIDER_HEIGHT
                  : REQUEST_HEIGHT + DIVIDER_HEIGHT,
              offset,
              index,
            };
          }}
        />
      ) : (
        overview && (
          <>
            <List overview={overview} />
            <FloatingAddButton />
          </>
        )
      )}
    </View>
  );
};

export default Search;
