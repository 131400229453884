import React from 'react';
import {createRoot} from 'react-dom/client';

import App from './App';

const bootstrap = async () => {
  const container = document.getElementById('root');
  const root = createRoot(container);
  root.render(<App />);
};
window.addEventListener('DOMContentLoaded', (event) => {
  bootstrap();
});
