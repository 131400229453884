import React, {useState, useEffect} from 'react';

import {service_request as serviceRequestApi} from '~/api/private';
import {Pressable} from '~/components/controls';

const IMG_DEFAULT_WIDTH = 800;

const Thumbnail = ({request_id, message_id, index, data}) => {
  const [size, setSize] = useState({
    width: IMG_DEFAULT_WIDTH,
    height: IMG_DEFAULT_WIDTH,
  });

  useEffect(() => {
    if (data) {
      const img = new Image();
      img.src = data;
      img.onload = () => {
        const ratio = img.height / img.width;
        setSize({width: IMG_DEFAULT_WIDTH, height: IMG_DEFAULT_WIDTH * ratio});
      };
    }
  }, [data]);

  return (
    <Pressable
      onPress={async () => {
        const w = window.open(
          null,
          'bahnkick_image',
          `popup,width=${size.width},height=${size.height}`,
        );

        w.document.write(
          `<html onclick="window.close()" style="margin: 0; padding: 0; overflow: auto; height: 100%; width: 100%; background-color: #f2f2f2;">
              <body style="margin: 0; padding: 0; overflow: auto; height: 100%; width: 100%; background-color: #f2f2f2;">
                <div style='text-align: center; margin-top: 200px;'>Bezig met laden...</div>
              </body>
           </html>`,
        );

        const {result} =
          (await serviceRequestApi.full_image(request_id, message_id, index)) ??
          {};

        if (!result) {
          w.document.body.innerHTML = `<div style='text-align: center; margin-top: 200px;'>Afbeelding laden is mislukt. Probeer het opnieuw.</div>`;
          w.document.close();

          setTimeout(() => {
            w.close();
          }, 2000);
          return;
        }

        w.document.body.innerHTML = `<img src="${result}" style="display: block; margin: 0 auto;" height="100%" />`;

        w.document.close();
      }}>
      <img
        draggable="false"
        style={{
          maxWidth: '50%',
          borderRadius: 10,
        }}
        src={data}
      />
    </Pressable>
  );
};

export default Thumbnail;
