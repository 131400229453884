import React, {useEffect, useState} from 'react';
import {View, ActivityIndicator, ScrollView} from 'react-native';
import {Divider} from 'react-native-paper';

import {service_request as serviceRequestApi} from '~/api/private';
import Format from '~/lib/format';
import {
  ROB_TIRE_EXCLUSIVE_CODES,
  TIRE_LOCATION_ORDER,
  TIRE_SOURCE,
} from '~/types/rob';

import sy from '~/styles';
import {
  Text,
  Pressable,
  Grouping,
  TireTooltip,
  ActionButton,
} from '~/components/controls';
import Actionsheet, {Title, Actions} from '~/components/controls/Actionsheet';
import withDimensions from '~/components/hoc/with-dimensions';

import ListIcon from '~/images/md-icons/list/materialicons/24px.svg';
import DeleteIcon from '~/images/md-icons/delete/materialicons/24px.svg';
import TireRepairIcon from '~/images/md-icons/tire_repair/materialicons/24px.svg';
import ArchiveIcon from '~/images/md-icons/archive/materialicons/24px.svg';
import UnarchiveIcon from '~/images/md-icons/unarchive/materialicons/24px.svg';

import {SeasonIndicator, Profile} from './shared';

import TireActions from './actions';

const HEADER_HEIGHT_IN_PX = 70;
const FOOTER_HEIGHT_IN_PX = 50;
const DIVIDER_HEIGHT_IN_PX = 1;

const TireIcon = ({action, ...rest}) => {
  let icon;
  switch (action) {
    case 'Current_Replace':
    case 'Current_Remove':
    case 'Stored_ReplaceNew':
      icon = <DeleteIcon {...rest} />;
      break;
    case 'Current_Store':
    case 'Current_StoreWheel':
      icon = <ArchiveIcon {...rest} />;
      break;
    case 'Current_TireRepair':
      icon = <TireRepairIcon {...rest} />;
      break;
    case 'Stored_WheelChange':
    case 'Stored_TireChange':
    case 'Stored_TireChangeThirdParty':
      icon = <UnarchiveIcon {...rest} />;
      break;
    default:
      icon = <ListIcon {...rest} />;
      break;
  }

  return icon;
};

const Tires = ({tires, onPress, onChangeProfileWidths}) => {
  const profile_widths = tires.reduce((acc, item) => {
    acc[item.location] = item.attributes?.profile_width;
    return acc;
  }, {});

  return tires.map((tire, index, array) => {
    const {location, action, attributes} = tire;
    const {rob_tire_id, title, description, seasonIndicator} = attributes;

    const is_last = index === array.length - 1;

    return (
      <View style={[sy['flex-row']]} key={location}>
        <Profile
          style={[sy['p-4']]}
          location={tire.location}
          widths={profile_widths}
          onChange={onChangeProfileWidths}
        />
        <View style={[sy['flex-1']]}>
          <View style={[sy['p-4'], sy['pl-0']]}>
            <Pressable
              style={[sy['flex-row'], sy['justify-between']]}
              onPress={() => onPress(location)}>
              <View style={[sy['flex-1']]}>
                <View style={[sy['flex-row'], sy['items-center'], sy['gap-2']]}>
                  <View style={[sy['flex-row']]}>
                    <Text style={{minWidth: 21}}>{location}</Text>
                    <Text>
                      &nbsp;&middot;&nbsp;
                      {rob_tire_id ? title : 'Onbekend'}
                    </Text>
                  </View>
                  <SeasonIndicator indicator={seasonIndicator} />
                </View>
                <Text
                  style={[
                    sy.smallRegular,
                    sy['text-lightgray'],
                    sy.underline,
                    sy.truncate,
                  ]}>
                  {rob_tire_id ? description : 'Keuze maken'}
                </Text>
              </View>
              {rob_tire_id && (
                <TireIcon action={action} width={24} height={24} />
              )}
            </Pressable>
          </View>
          {!is_last && <Divider style={sy['bg-gray95']} />}
        </View>
      </View>
    );
  });
};

const NewTires = ({tires, lessor, brands, onPress, onChangeProfileWidths}) => {
  const profile_widths = tires.reduce((acc, item) => {
    acc[item.location] = item.profile_width;
    return acc;
  }, {});

  return tires.map((tire, index, array) => {
    const {
      location,
      title,
      description,
      rob_tire_id,
      source,
      price,
      manufacturerSuggestedRetailPrice,
      discount,
      makeCode,
      seasonIndicator,
    } = tire;

    const is_last = index === array.length - 1;

    return (
      <View style={[sy['flex-row']]} key={location}>
        <Profile
          style={[sy['p-4']]}
          location={location}
          widths={profile_widths}
          onChange={onChangeProfileWidths}
        />
        <View style={[sy['flex-1']]}>
          <View style={[sy['p-4'], sy['pl-0']]}>
            <View
              style={[
                sy['flex-row'],
                sy['justify-between'],
                sy['items-center'],
              ]}>
              <Pressable
                style={[sy['flex-1']]}
                onPress={() => onPress(location)}>
                <View style={[sy['flex-row'], sy['items-center'], sy['gap-2']]}>
                  <View style={[sy['flex-row']]}>
                    <Text style={{minWidth: 21}}>{location}</Text>
                    <Text>
                      &nbsp;&middot;&nbsp;
                      {rob_tire_id ? title : 'Onbekend'}
                    </Text>
                  </View>
                  <SeasonIndicator indicator={seasonIndicator} />
                </View>
                <Text
                  style={[
                    sy.smallRegular,
                    sy['text-lightgray'],
                    sy.underline,
                    sy.truncate,
                  ]}>
                  {rob_tire_id ? description : 'Keuze maken'}
                </Text>
              </Pressable>
              {rob_tire_id ? (
                <View style={[sy['pl-2']]}>
                  <Text style={sy.smallRegular}>
                    {[
                      TIRE_SOURCE.Reserve,
                      TIRE_SOURCE.LessorStock,
                      TIRE_SOURCE.ThirdParty,
                      TIRE_SOURCE.Private,
                    ].includes(source)
                      ? null
                      : Format.price(price - discount)}
                  </Text>
                  <View style={[sy['flex-row'], sy['justify-end']]}>
                    {[
                      TIRE_SOURCE.Reserve,
                      TIRE_SOURCE.LessorStock,
                      TIRE_SOURCE.ThirdParty,
                      TIRE_SOURCE.Private,
                    ].includes(source) ? null : (
                      <TireTooltip
                        lessor={lessor}
                        manufacturerSuggestedRetailPrice={
                          manufacturerSuggestedRetailPrice
                        }
                        order={brands[makeCode]?.[0]?.order}
                        discount={Format.round(
                          1 - discount / manufacturerSuggestedRetailPrice,
                          2,
                        )}
                      />
                    )}
                  </View>
                </View>
              ) : (
                <></>
              )}
            </View>
          </View>
          {!is_last && <Divider style={sy['bg-gray95']} />}
        </View>
      </View>
    );
  });
};

const TiresActionsheet = ({
  dimensions,
  license,
  is_fleet_car,
  is_retail_car,
  request_id,
  onDismiss,
  onChange,
}) => {
  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState({});
  const [isDirty, setIsDirty] = useState(false);
  const [lessor, setLessor] = useState(null);
  const [brands, setBrands] = useState(null);

  const [showActions, setShowActions] = useState(null);

  useEffect(() => {
    const fetch = async () => {
      const {result: sla} = await serviceRequestApi.sla(request_id);
      setLessor(sla.lessor);
      const brands_sla =
        sla?.tires.brands.reduce((acc, item) => {
          acc[item.makeCode] = [
            ...(acc[item.makeCode] ?? []),
            {
              discount: item.discount,
              condition: item.condition,
              order: item.order,
            },
          ];
          return acc;
        }, {}) ?? {};
      setBrands(brands_sla);

      const {result: tires} = await serviceRequestApi.tires(request_id);
      setSelected(tires);

      setLoading(false);
    };

    fetch();
  }, [request_id]);

  useEffect(() => {
    if (!loading) {
      setIsDirty(true);
    }
  }, [request_id, loading, selected]);

  const onOK = async () => {
    if (!isDirty) {
      onDismiss();
    } else {
      onChange(selected);
    }
  };

  const onShowActions = (location) => {
    setShowActions({
      location,
      selected: location.startsWith('X')
        ? {[location]: selected[location]}
        : Object.fromEntries(
            Object.entries(selected).filter(([k]) => !k.startsWith('X')),
          ),
    });
  };

  const mounted = Object.keys(selected)
    .sort(
      (a, b) => TIRE_LOCATION_ORDER.indexOf(a) - TIRE_LOCATION_ORDER.indexOf(b),
    )
    .map((location) => ({
      location,
      action: selected[location].action,
      attributes: selected[location].attributes,
    }));

  const stored = Object.keys(selected)
    .sort(
      (a, b) => TIRE_LOCATION_ORDER.indexOf(a) - TIRE_LOCATION_ORDER.indexOf(b),
    )
    .filter((location) => selected[location].stored.action)
    .map((location) => ({
      location,
      action: selected[location].stored.action,
      attributes: selected[location].stored.attributes,
    }));

  const new_tires = Object.keys(selected)
    .sort(
      (a, b) => TIRE_LOCATION_ORDER.indexOf(a) - TIRE_LOCATION_ORDER.indexOf(b),
    )
    .filter((location) => selected[location].new_tire)
    .map((location) => ({
      location,
      ...selected[location].new_tire,
    }));

  if (showActions) {
    return (
      <TireActions
        request_id={request_id}
        license={license}
        is_fleet_car={is_fleet_car}
        is_retail_car={is_retail_car}
        location={showActions.location}
        selected={showActions.selected}
        onDismiss={() => {
          setShowActions(null);
        }}
        onChange={(changes) => {
          setSelected((prevState) => {
            const {location} = showActions;
            const rob_code = changes[location]?.rob_code;

            let rest = {...(prevState ?? {})};
            if (ROB_TIRE_EXCLUSIVE_CODES.includes(rob_code)) {
              rest = Object.fromEntries(
                Object.entries(rest).map((entry) => {
                  if (
                    ROB_TIRE_EXCLUSIVE_CODES.includes(entry[1].rob_code) &&
                    entry[1].rob_code !== rob_code
                  ) {
                    return [
                      entry[0],
                      {
                        ...entry[1],
                        rob_code: null,
                        action: null,
                        stored: {
                          ...entry[1].stored,
                          action: null,
                        },
                        new_tire: null,
                      },
                    ];
                  }

                  return entry;
                }),
              );
            }

            return {...rest, ...changes};
          });

          setShowActions(null);
        }}
      />
    );
  }

  return (
    <>
      <Actionsheet visible={true} onDismiss={onDismiss}>
        {loading && (
          <View style={[sy['p-4']]}>
            <ActivityIndicator size="large" color="#231fda" />
          </View>
        )}
        {!loading && (
          <>
            <Title>
              <Text style={sy.largePlus}>Huidige banden</Text>
            </Title>
            <Divider />
            <ScrollView
              contentContainerStyle={{
                maxHeight:
                  dimensions.window.height -
                  HEADER_HEIGHT_IN_PX -
                  DIVIDER_HEIGHT_IN_PX -
                  FOOTER_HEIGHT_IN_PX -
                  DIVIDER_HEIGHT_IN_PX,
              }}>
              <Tires
                tires={mounted}
                onPress={onShowActions}
                onChangeProfileWidths={(values) => {
                  setSelected((prevState) => {
                    const newState = {...prevState};

                    Object.keys(values).forEach((location) => {
                      newState[location].attributes.profile_width =
                        values[location];
                    });

                    return newState;
                  });
                }}
              />
              {new_tires.length > 0 && (
                <Grouping header="Nieuw" initial={{expand: true}}>
                  <NewTires
                    tires={new_tires}
                    lessor={lessor}
                    brands={brands}
                    onPress={onShowActions}
                    onChangeProfileWidths={(values) => {
                      setSelected((prevState) => {
                        const newState = {...prevState};

                        Object.keys(values).forEach((location) => {
                          newState[location].new_tire.profile_width =
                            values[location];
                        });

                        return newState;
                      });
                    }}
                  />
                </Grouping>
              )}
              {stored?.length > 0 && (
                <Grouping header="Uit opslag" initial={{expand: true}}>
                  <Tires
                    tires={stored}
                    onPress={onShowActions}
                    onChangeProfileWidths={(values) => {
                      setSelected((prevState) => {
                        const newState = {...prevState};

                        Object.keys(values).forEach((location) => {
                          newState[location].stored.attributes.profile_width =
                            values[location];
                        });

                        return newState;
                      });
                    }}
                  />
                </Grouping>
              )}
            </ScrollView>
            <Divider />
            <Actions>
              <ActionButton onPress={onDismiss}>Annuleren</ActionButton>
              <ActionButton onPress={onOK}>OK</ActionButton>
            </Actions>
          </>
        )}
      </Actionsheet>
    </>
  );
};

export default withDimensions(TiresActionsheet);
