import React, {useEffect, useState, useCallback} from 'react';
import {View, ScrollView, ActivityIndicator} from 'react-native';
import {Divider} from 'react-native-paper';
import _ from 'lodash';
import {v4 as uuid} from 'uuid';

import sy from '~/styles';
import {
  Text,
  Checkbox,
  Pressable,
  Link,
  FourceDetailsLink,
  ArticleText,
  ActionButton,
  Dialog,
} from '~/components/controls';
import Actionsheet, {
  Title,
  Content,
  Actions,
} from '~/components/controls/Actionsheet';
import {AddPartDialog} from '~/components//shared/Dialogs';
import {
  articles as articlesApi,
  service_request as serviceRequestApi,
  dealer as dealerApi,
  rob as robApi,
} from '~/api/private';
import Format from '~/lib/format';
import {SOURCES} from '~/types/sources';
import withDimensions from '~/components/hoc/with-dimensions';

import AddIcon from '~/images/md-icons/add/materialicons/24px.svg';

const HEADER_HEIGHT_IN_PX = 72;
const FOOTER_HEIGHT_IN_PX = 50;

const AddPart = ({
  dismissable = true,
  onDismiss,
  dimensions,
  request_id,
  dealer_id,
  license,
  parts: task_parts,
  lessor,
  rob_code,
  onChange,
}) => {
  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState([]);
  const [articles, setArticles] = useState([]);

  const [editCustomPart, setEditCustomPart] = useState(null);
  const [showAddEditPartDialog, setShowAddEditPartDialog] = useState(false);
  const [showCancelAddPartDialog, setShowCancelAddPartDialog] = useState(false);

  const refresh = useCallback(async () => {
    const {result: sla_result} = await serviceRequestApi.sla(request_id);
    const {result: dealer_parts} = await dealerApi.parts(dealer_id, license);
    const {result: code_articles} = await robApi.articles(lessor, rob_code);

    let articles = [
      ...Object.values(
        sla_result.dealer_parts?.reduce((acc, item) => {
          acc[item.description] = item;
          return acc;
        }, {}) ?? {},
      ),
      ...(dealer_parts ?? []).map((item) => ({
        ...item,
        source: SOURCES.Custom,
      })),
    ];

    if (code_articles.length > 0) {
      const gen_arts = [
        ...new Set([
          ...code_articles.map((item) => item.gen_art_nr).filter(Boolean),
        ]),
      ];

      if (gen_arts.length) {
        const {result} = await articlesApi.search_by_gen_arts(
          license,
          dealer_id,
          gen_arts,
        );

        articles = [...articles, ...(result || [])];
      }
    }

    setArticles(
      articles
        .filter((item) =>
          (code_articles ?? []).some((x) => x.reference === item.reference),
        )
        .map((item) => {
          const task_part = task_parts?.find(
            (x) => x.reference === item.reference,
          );
          return {
            ...item,
            ...(task_part ?? {}),
          };
        }),
    );
  }, [request_id, dealer_id, lessor, license, rob_code]);

  useEffect(() => {
    const fetch = async () => {
      await refresh();
      setLoading(false);
    };

    fetch();
  }, [request_id, refresh]);

  const onAddDealerPart = async (part) => {
    const article_id = uuid();

    const article = {
      id: article_id,
      reference: part.reference,
      description: part.description,
      number: part.number,
      price: part.price,
      source: part.source,
      quantity: part.quantity ?? 1,
      attributes: {
        itemMpId: article_id,
      },
    };

    setSelected([...selected, article]);

    setArticles([...articles, article]);

    setShowAddEditPartDialog(false);
  };

  const onEditedDealerPart = async (article) => {
    await refresh();
    if (selected?.map((item) => item.id).includes(article.id)) {
      setSelected([
        ...selected.filter((item) => item.id !== article.id),
        {
          id: article.id,
          description: article.description,
          number: article.number,
          price: article.price,
          source: article.source,
          quantity: article.quantity ?? 1,
          attributes: {
            itemMpId: article.id,
          },
        },
      ]);
    }

    setArticles([
      ...articles.filter((item) => item.id !== article.id),
      article,
    ]);

    setEditCustomPart(null);
    setShowAddEditPartDialog(false);
  };

  const onDeletedDealerPart = async () => {
    setSelected([...selected.filter((item) => item.id !== editCustomPart.id)]);
    setArticles([...articles.filter((item) => item.id !== editCustomPart.id)]);
    setEditCustomPart(null);

    await refresh();
    setShowAddEditPartDialog(false);
  };

  const onOK = () => {
    onChange(selected);
  };

  return (
    <Actionsheet visible={true} dismissable={dismissable} onDismiss={onDismiss}>
      {loading && (
        <View style={[sy['p-4']]}>
          <ActivityIndicator size="large" color="#231fda" />
        </View>
      )}
      {!loading && (
        <>
          <Title>
            <View
              style={[
                sy['flex-row'],
                sy['justify-between'],
                sy['items-center'],
              ]}>
              <Text style={[sy.largePlus, {lineHeight: 30}]}>
                Artikel toevoegen
              </Text>
            </View>
          </Title>
          <Divider />
          <Content style={{paddingBottom: 0, paddingHorizontal: 0}}>
            <ScrollView
              contentContainerStyle={{
                maxHeight:
                  dimensions.window.height -
                  HEADER_HEIGHT_IN_PX -
                  FOOTER_HEIGHT_IN_PX,
              }}>
              {articles
                ?.sort((a, b) => {
                  if (!_.isNumber(a.margin) && !_.isNumber(b.margin)) {
                    return a.description.localeCompare(b.description);
                  }

                  if (!_.isNumber(a.margin)) {
                    return 1;
                  }

                  if (!_.isNumber(b.margin)) {
                    return -1;
                  }

                  if (a.margin === b.margin) {
                    return a.description.localeCompare(b.description);
                  }

                  return b.margin - a.margin;
                })
                .map((item) => (
                  <View
                    key={item.id ?? item.description}
                    style={[sy['flex-row']]}>
                    <Pressable
                      style={[sy['p-4'], sy['pr-8']]}
                      disabled={task_parts?.some((x) => x.id === item.id)}
                      onPress={() => {
                        const has_lead_surcharge = item.lead_surcharge > 0;
                        if (selected.some((x) => x.id === item.id)) {
                          setSelected(
                            selected.filter(
                              (x) =>
                                x.id !== item.id &&
                                (has_lead_surcharge
                                  ? x.id !== 'LEAD_SURCHARGE'
                                  : true),
                            ),
                          );
                        } else {
                          setSelected(
                            [
                              ...selected,
                              {
                                id: item.id,
                                description: item.description,
                                number: item.number,
                                price: item.price,
                                source: item.source,
                                quantity: item.quantity ?? 1,
                                attributes: {
                                  itemMpId: item.id,
                                },
                                gen_art_nr: item.gen_art_nr,
                              },
                              has_lead_surcharge
                                ? {
                                    id: 'LEAD_SURCHARGE',
                                    description: 'Loodtoeslag',
                                    price: item.lead_surcharge,
                                    source: item.source,
                                    quantity: 1,
                                    attributes: {
                                      itemMpId: 'LEAD_SURCHARGE',
                                    },
                                  }
                                : null,
                            ].filter(Boolean),
                          );
                        }
                      }}>
                      <Checkbox
                        checked={
                          task_parts?.some((x) => x.id === item.id) ||
                          selected.some((x) => x.id === item.id)
                        }
                        disabled={task_parts?.some((x) => x.id === item.id)}
                        disabledTimeout={0}
                      />
                    </Pressable>
                    <View
                      style={[
                        sy['flex-row'],
                        sy['flex-1'],
                        sy['justify-between'],
                        sy['py-4'],
                        sy['pr-4'],
                        sy['border-b'],
                        sy['border-lightgray'],
                      ]}>
                      <View style={[sy['flex-1']]}>
                        <Text style={[sy.truncate]}>{item.description}</Text>
                        {item.source === SOURCES.Custom ? (
                          <Link
                            textStyle={[sy.small, sy['text-lightgray']]}
                            onPress={() => {
                              setSelected([item]);
                              setEditCustomPart(item);
                              setShowAddEditPartDialog(true);
                            }}>
                            <ArticleText
                              source={item.source}
                              number={item.number}
                            />
                          </Link>
                        ) : item.source === SOURCES.Fource ? (
                          <FourceDetailsLink
                            license={license}
                            dealer_id={dealer_id}
                            article={item}
                          />
                        ) : (
                          <Text style={[sy.small, sy['text-lightgray']]}>
                            <ArticleText
                              source={item.source}
                              number={item.number}
                            />
                          </Text>
                        )}
                      </View>
                      <View style={sy['items-end']}>
                        <Text style={sy.smallRegular}>
                          {Format.price(item.price)}
                        </Text>
                        {item.margin > 0 && (
                          <Text style={[sy.small, sy['text-lightgray']]}>
                            {Format.number(item.margin * 100, 0)}%
                          </Text>
                        )}
                      </View>
                    </View>
                  </View>
                ))}
              <Pressable
                style={[sy['p-4'], sy['flex-row']]}
                onPress={() => setShowAddEditPartDialog(true)}>
                <View style={[sy['pr-8']]}>
                  <AddIcon fill="#231fda" />
                </View>
                <Text>Artikel invoeren</Text>
              </Pressable>
            </ScrollView>
          </Content>
          <Divider />
          <Actions>
            <ActionButton
              onPress={() => {
                if (selected?.length > 0) {
                  setShowCancelAddPartDialog(true);
                } else {
                  onDismiss();
                }
              }}>
              Annuleren
            </ActionButton>
            <ActionButton onPress={onOK}>OK</ActionButton>
          </Actions>
        </>
      )}
      {showAddEditPartDialog && (
        <AddPartDialog
          dealer_id={dealer_id}
          license={license}
          edit_article={editCustomPart}
          onDismiss={() => {
            setEditCustomPart(null);
            setShowAddEditPartDialog(false);
          }}
          onAdd={onAddDealerPart}
          onEdited={onEditedDealerPart}
          onDeleted={onDeletedDealerPart}
        />
      )}
      <Dialog
        visible={showCancelAddPartDialog}
        title="Selectie niet toevoegen?"
        onDismiss={() => setShowCancelAddPartDialog(false)}
        buttons={[
          {
            text: 'Annuleren',
            onPress: () => setShowCancelAddPartDialog(false),
          },
          {
            text: 'Niet toevoegen',
            onPress: onDismiss,
          },
        ]}>
        <View style={[sy['py-4']]}>
          <Text>
            Weet je zeker dat je dit venster wil sluiten zonder de selectie toe
            te voegen?
          </Text>
        </View>
      </Dialog>
    </Actionsheet>
  );
};

export default withDimensions(AddPart);
