import endpoints from './endpoints';
import {throttled, fetch} from '~/lib/fetch';
import version from '~/app-version';

const user = {
  me: throttled(async () => {
    const result = await fetch(`${endpoints.user}?v=${version}`);

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  }),
  find: async (user_id) => {
    const result = await fetch(`${endpoints.user}/find/${user_id}`);

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
  logout: async () => {
    const result = await fetch(`${endpoints.user}/logout`, {
      method: 'POST',
    });

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
  update: async (data) => {
    const result = await fetch(`${endpoints.user}`, {
      method: 'POST',
      body: JSON.stringify(data),
    });

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
  update_settings: async (settings) => {
    const result = await fetch(`${endpoints.user}`, {
      method: 'PATCH',
      body: JSON.stringify({settings: {partner: {...settings}}}),
    });

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
};

export default user;
